html {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.m-t-35 {
    margin-top: 35px;
}

.m-b-20 {
    margin-bottom: 20px;
}

header#top {
    border-bottom: 2px solid #13f22d;
    margin-bottom: 0;
    /*background-color: teal;*/
    background: url("images/MAPBLE.jpg") repeat-x;
}

a:hover {
    text-decoration: none;
}

.truncate {
    width: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav > li > a:focus, .nav > li > a:hover, .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background: none;
}

.navbar-middle > li > a:focus, .navbar-middle > li > a:hover, .navbar-middle > li > a.active {
    color: #13f22d;
    text-shadow: 4px 3px 6px rgba(0, 0, 0, 1);
}

.navbar-header {
    margin: 0 0 15px 0;
}

.navbar-brand {
    padding: 0;
    height: 74px;
}

.navbar-header .navbar-brand > img {
    height: 100%;
    margin-top: 10px;
}

.navbar-middle {
    padding: 15px 0 0 0;
}

.navbar-middle > li > a {
    font-size: 18px;
}

.navbar-middle li > a {
    padding: 25px;
    color: #fff;
}

.navbar-nav > li > .dropdown-menu {
    margin-top: 4px;
    padding: 0;
}

.navbar-middle .sub-menu li {
    background-color: #00a652;
    color: #fff;
    cursor: pointer;
}

.dropdown-menu > li > a {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: #f68c0c;
    color: #fff;
}

.bs-docs-nav .navbar-toggle .icon-bar {
    background-color: #13f22d;
}

.navbar-auth {
    margin-top: 10px;
}

.navbar-auth .register a {
    color: #13f22d;
    font-weight: 600;
}

.navbar-auth .login {
    background-color: #13f22d;
    border-radius: 5px;
    margin-top: 8px;
}

.navbar-auth .login a {
    font-weight: 600;
    color: #fff;
    padding: 8px 15px;
}

.fraction-slider {
    z-index: -1;
}

.fs-pager-wrapper {
    left: 50%;
}

.slide {
    text-transform: uppercase;
}

.big-text {
    font-size: 48px;
}

.btn1 {
    font-size: 16px;
    padding: 15px 30px;
    color: #333;
    border: 2px solid #333;
    border-radius: 25px;
}

.btn2 {
    font-size: 16px;
    padding: 18px 60px;
    color: #fff;
    background-color: #13f22d;
    border-radius: 25px;
}

#caycanh {
    /*background: url("images/bg.jpg") center repeat;*/
    /*padding: 50px 0;*/
}

.embed-responsive-item {
    padding: 5px;
}

.text-block {
    text-align: left;
}

.title {
    color: #ba2e2e;
    text-transform: uppercase;
    font-size: 24px;
}

.more a {
    color: #13f22d;
    margin-top: 20px;
}

.search-bar {
    position: absolute;
    top: 30px;
    right: 15px;
}

/*Porfolio*/

.position {
    margin: 30px 0;
}

.position ul {
    font-size: 0;
}

.position ul li {
    background-color: #00a652;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    border: 1px solid #e7e7e7;
    color: #fff;
    cursor: pointer;
}

.position ul li.current, .position ul li:hover {
    background-color: #f68c0c;
    color: #fff;
}

.position ul li a {
    color: #999999;
}

.position ul li.current a {
    color: #fff;
}

#filters {
    margin: 1%;
    padding: 0;
    list-style: none;
}

#filters li span {
    font-family: "Times New Roman";
    display: block;
    padding: 7px 15px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

#filters li span.active {
    color: #fff;
}

.products, .beauty-place {
    margin-bottom: 20px;
}

.thumbnail {
    margin-bottom: 0;
}

.product-name {
    height: 70px;
    padding: 10px 5px;
    font-size: 14px;
    font-weight: bold;
    color: #ba2e2e;
    overflow: hidden;
}

.price {
    font-size: 16px;
    font-weight: bold;
    padding: 0 5px;
}

.view_more {
    padding: 20px 5px;
}

.view_more a {

}

#portfoliolist {
    overflow: hidden;
    margin-top: 22px;
}

.portfolio {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    width: 48%;
    margin: 1%;
    display: none;
    float: left;
}

.portfolio-wrapper {
    overflow: hidden;
    position: relative !important;
    background: #666;
    -webkit-box-shadow: 5px 4px 13px 0 rgba(44, 44, 39, 0.65);
    -moz-box-shadow: 5px 4px 13px 0 rgba(44, 44, 39, 0.65);
    box-shadow: 5px 4px 13px 0 rgba(44, 44, 39, 0.65);
}

.portfolio figure {
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
}

.portfolio figure img {
    display: block;
    max-width: 100%;
    position: relative;
    z-index: 10;
    height: 140px;
    width: 100%;
}

.portfolio figure figcaption {
    display: block;
    position: absolute;
    z-index: 5;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.portfolio figure h2 {
    color: #fff;
    font-size: 20px;
    text-align: left
}

.portfolio figure p {
    display: block;
    font-size: 15px;
    margin: 0;
    text-align: left;
    text-shadow: 2px 2px 0px #fff;
}

.portfolio figure figcaption {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px 10px;
    text-align: center;
    backface-visibility: hidden;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s
}

.portfolio figure img {
    backface-visibility: hidden;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s
}

.portfolio figure:hover img, figure.hover img {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.portfolio figure:hover figcaption, figure.hover figcaption {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0)
}

.pager {
    display: inline-block;
    padding: 5px 9px;
    margin-right: 4px;
    border-radius: 3px;
    border: solid 1px #c0c0c0;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f8f8f8), to(#e9e9e9));
    background: -moz-linear-gradient(0% 0% 270deg, #f8f8f8, #e9e9e9);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .8), 0 1px 3px rgba(0, 0, 0, .1);
    font-size: .875em;
    font-weight: bold;
    text-decoration: none;
    color: #717171;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
    cursor: pointer;
}

.pager:hover, .pager.gradient:hover {
    background: #fefefe;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FEFEFE), to(#f0f0f0));
    background: -moz-linear-gradient(0% 0% 270deg, #FEFEFE, #f0f0f0);
}

.pager.active {
    border: none;
    background: #616161;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .8);
    color: #f0f0f0;
    text-shadow: 0 0 3px rgba(0, 0, 0, .5);
}

.pager.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.scrollup {
    background: url(images/up-arrow.png) no-repeat;
    bottom: 10px;
    height: 51px;
    position: fixed;
    right: 10px;
    width: 51px;
    z-index: 9999;
}

#carousel {
    margin-top: 27px;
}

#carousel .thumbnail {
    margin-bottom: 20px;
}

.slick-slide a img {
    width: 100%;
    height: 225px;
}

.slider-home .slick-slide a img {
    height: 300px;
}

.hd-icon {
    height: 49px;
    width: 49px;
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    background: url('images/icon_phone.png') no-repeat center;
    float: left;
    margin-right: 25px;
}

.icon-location {
    background: url('images/location.png') no-repeat center;
}

.icon-mail {
    background: url('images/icon_mail.png') no-repeat center;
}

.location-item {
    overflow: hidden;
    margin-bottom: 15px;
}

.location-item.address, .location-item.phone {
    font-weight: bold;
}

.location-item.mail {
    color: #c6a842;
}

.hd-info {
    margin-top: 10px;
}

#map_block {
    margin-bottom: 30px;
}

#map {
    width: 500px;
    height: 400px;
}

#location .image {
    margin-bottom: 30px;
}

#location .image img {
    width: 100%;
}

.content-wrapper {
    margin-bottom: 35px;
}

.feature_image {
    margin-bottom: 30px;
}

.feature_image img {
    width: 100%;
}

.slick-prev:before, .slick-next:before {
    color: red;
}

/*footer*/
#page-wrapper {
    padding-bottom: 90px;
}

.footer-distributed {
    background-color: teal;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 39%;
}

/* The company logo */

.footer-distributed h3 {
    color: #13f22d;
    text-shadow: 4px 3px 3px rgba(0, 0, 0, 1);
}

.footer-distributed h3 span {
    color: #5383d3;
}

/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 40%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: #5383d3;
    text-decoration: none;;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 20%;
}

.footer-distributed .footer-right a {
    color: #fff;
}

.footer-distributed .footer-right a:hover {
    color: #13f22d;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;

    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}

.relative {
    position: relative;
    overflow: hidden;
}

.icon-left {
    float: left;
    width: 38px;
}

.icon-right {
    float: left;
    min-height: 38px;
    word-break: break-word;
}

.icon-right::before {
    content: "a";
    display: block;
    visibility: hidden;
    height: 50%;
}

@media (max-width: 880px) {

    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    .footer-distributed .footer-center i {
        margin-left: 0;
    }

}

.content-list .position ul li {
    padding: 5px 10px;
}

/*media*/
@media (max-width: 879px) {
    .footer-distributed .media-left {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .navbar-right {
        margin-right: 0;
    }

    .portfolio {
        width: 31%;
    }
}

@media (max-width: 767px) {
    #bs-navbar {
        margin-top: 15px;
    }

    .navbar-middle .sub-menu li {
        background-color: teal;
        padding: 0 20px;
    }

    .search-bar {
        width: 220px;
        top: 10px;
        right: 70px;
    }
}